import axios, { AxiosInstance } from "axios";

export type Game = {
  provider: string;
  id: string;
  title: string;
  imageUrl: string;
};

export type OpenGame = {
  url: string;
};

export class JgaAPI {
  private static jgaApiInstance: JgaAPI;
  private axios: AxiosInstance;

  private constructor() {
    this.axios = axios.create({
      baseURL: "https://games.playslotsmobile.com/api/v1/jga",
      withCredentials: true,
    });
  }

  public static getInstance(): JgaAPI {
    if (JgaAPI.jgaApiInstance !== undefined) {
      return JgaAPI.jgaApiInstance;
    }

    JgaAPI.jgaApiInstance = new JgaAPI();
    return JgaAPI.jgaApiInstance;
  }

  public async getGames(): Promise<Game[] | null> {
    try {
      const resp = await this.axios.get<Game[]>("/games");
      return resp.data;
    } catch (e) {}
    return null;
  }

  public async openGame(
    playerId: string,
    operatorId: string,
    game: Game,
  ): Promise<OpenGame | null> {
    try {
      const resp = await this.axios.post<OpenGame>("/open-game", {
        player_id: playerId,
        operator_id: operatorId,
        owner_id: operatorId,
        vendor_id: operatorId,
        master_id: operatorId,
        provider: game.provider,
        game_id: game.id,
      });
      return resp.data;
    } catch (e) {}
    return null;
  }
}
