import { useEffect, useState } from "react";
import { Game, JgaAPI } from "./jga-api";
import Logo from "./psm-logo.png";

function App() {
  const perPage = 20;

  const [games, setGames] = useState<Game[]>([]);
  const [filteredGames, setFilteredGames] = useState<Game[]>([]);
  const [playerId, setPlayerId] = useState("");
  const [operatorId, setOperatorId] = useState("");
  const [paginationInfo, setPaginationInfo] = useState({
    totalPages: 0,
    currentPage: 0,
  });

  useEffect(() => {
    async function loadGames() {
      const gamesFromApi = await JgaAPI.getInstance().getGames();
      if (gamesFromApi === null) {
        alert("Failed To Load Games!");
        return;
      }
      setGames(gamesFromApi);

      setFilteredGames(gamesFromApi);
      setPaginationInfo({
        totalPages: Math.ceil(gamesFromApi.length / perPage),
        currentPage: 1,
      });
    }

    loadGames();
  }, []);

  const onOpenGame = async (game: Game) => {
    if (playerId.length <= 0) {
      alert("Set a player id first");
      return;
    }

    if (operatorId.length <= 0) {
      alert("Set a player id first");
      return;
    }

    const openGame = await JgaAPI.getInstance().openGame(
      playerId,
      operatorId,
      game,
    );
    if (openGame === null) {
      alert("Failed to open game!");
      return;
    }

    window.open(openGame.url, "_blank");
  };

  const onFilterGamesByName = (gameName: string) => {
    const filtered = games.filter((e) =>
      e.title.toLowerCase().includes(gameName.toLowerCase()),
    );
    setFilteredGames(filtered);
    setPaginationInfo({
      totalPages: Math.ceil(filtered.length / perPage),
      currentPage: 1,
    });
  };

  const handlePageChange = (page: number) => {
    setPaginationInfo((prevState) => ({
      ...prevState,
      currentPage: page,
    }));
  };

  const getPaginatedGames = () => {
    const startIndex = (paginationInfo.currentPage - 1) * perPage;
    return filteredGames.slice(startIndex, startIndex + perPage);
  };

  return (
    <div className="App">
      <div className="header">
        <img src={Logo} className="logo" alt="Logo" />
        <h1>PlaySlotsMobile Test Lobby</h1>
        <div className="inputs-box">
          <input
            type="text"
            className="search-box"
            placeholder="Search games..."
            onChange={(e) => onFilterGamesByName(e.target.value)}
          />
          <input
            type="text"
            className="search-box"
            placeholder="Enter Player ID here"
            onChange={(e) => setPlayerId(e.target.value)}
          />
          <input
            type="text"
            className="search-box"
            placeholder="Enter Operator ID here"
            onChange={(e) => setOperatorId(e.target.value)}
          />
        </div>
      </div>
      <div className="games-box">
        {getPaginatedGames().map((e) => (
          <div className="games-box-item" key={`${e.provider}-${e.id}`}>
            <img src={e.imageUrl} className="game-img" alt={e.title} />
            <p className="game-provider">{e.provider}</p>
            <button
              className="pagination-button play-btn"
              onClick={() => {
                onOpenGame(e);
              }}
            >
              Play
            </button>

            <p className="game-title">{e.title}</p>
          </div>
        ))}
      </div>
      <div className="pagination">
        <button
          className="pagination-button"
          disabled={paginationInfo.currentPage === 1}
          onClick={() => handlePageChange(paginationInfo.currentPage - 1)}
        >
          Previous
        </button>
        <span className="pagination-info">
          Page {paginationInfo.currentPage} of {paginationInfo.totalPages}
        </span>
        <button
          className="pagination-button"
          disabled={paginationInfo.currentPage === paginationInfo.totalPages}
          onClick={() => handlePageChange(paginationInfo.currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default App;
